import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Divider } from '@material-ui/core';
import { Topbar, Footer, Sidebar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
}));

const Main = ({ children, themeToggler, themeMode }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const pages = {
    landings: {
      title: 'Our story',
      id: 'landing-pages',
      children: {
        services: {
          groupTitle: 'comapny',
          pages: [
            {
              title: 'Our Story',
              href: '/Ourstory',
            },
            {
              title: 'Knowledge hub',
              href: '/blog-reach-view',
            },
            {
              title: 'Career',
              href: '/career-listing-minimal/',
            },
            {
              title: 'Products',
              href: '/Genomic',
            },
          
            // {
            //   title: 'Coworking',
            //   href: '/coworking',
            // },
            // {
            //   title: 'Rental',
            //   href: '/rental',
            // },
            // {
            //   title: 'Job Listing',
            //   href: '/job-listing',
            // },
            // {
            //   title: 'E-Learning',
            //   href: '/e-learning',
            // },
            // {
            //   title: 'E-commerce',
            //   href: '/e-commerce',
            // },
            // {
            //   title: 'Expo',
            //   href: '/expo',
            // },
          ],
        },
        apps: {
          groupTitle: 'Apps',
          pages: [
            {
              title: 'Desktop App',
              href: '/Launch',
            },
            {
              title: 'Mobile App',
              href: '/Launch',
            },
          ],
        },
        web: {
          groupTitle: 'Web',
          pages: [
            // {
            //   title: 'Marketing',
            //   href: '/',
            // },
            {
              title: 'Overview',
              href: '/Launch',
            },
            {
              title: 'Basic',
              href: '/Launch',
            },
            {
              title: 'Tsm',
              href: '/Joba',
            },
            {
              title: 'Tsm',
              href: '/Fse',
            },
            // {
            //   title: 'Service',
            //   href: '/service',
            // },
            // {
            //   title: 'Startup',
            //   href: '/startup',
            // },
            // {
            //   title: 'Enterprise',
            //   href: '/enterprise',
            // },
            // {
            //   title: 'Cloud Hosting',
            //   href: '/cloud-hosting',
            // },
            // {
            //   title: 'Agency',
            //   href: '/agency',
            // },
            // {
            //   title: 'Featuredrange',
            //   href: '/Featuredrange',
            // },
            // {
            //   title: 'Design Company',
            //   href: '/design-company',
            // },
            // {
            //   title: 'Logistics',
            //   href: '/logistics',
            // },
          ],
        },
      },
    },
    pages: {
      title: 'Products',
      id: 'supported-pages',
      children: {
        career: {
          groupTitle: 'Featured',
          pages: [
            {
              title: 'Chromium Controller',
             href: '/Genomic/?pid=genomicrange',
            },
            {
              title: 'Novaseq X',
              href: '/Genomic/?pid=illuminia',
            },
            {
              title: 'Cytof XT',
              href: '/Genomic/?pid=fluidigm',
            },
            {
              title: 'Bio Xp',
              href: '/Genomic/?pid=codex',
            },
            {
              title: 'Twist Bioscience',
              href: '',
            },
          ],
        },
        // helpCenter: {
        //   groupTitle: 'Help center',
        //   pages: [
        //     {
        //       title: 'Overview',
        //       href: '/',
        //     },
        //     {
        //       title: 'Article',
        //       href: '/',
        //     },
        //     {
        //       title: 'Auth',
        //       href: '/signin-simple',
        //     },
        //   ],
        // },
        company: {
          groupTitle: 'genomics',
          pages: [
            {
              title: 'Illumina',
              href: '/Genomic/?pid=illuminia',
            },
            {
              title: '10x Genomics',
              href: '/Genomic/?pid=genomicrange',
            },
            {
              title: 'Fluidigm',
              href: '/Genomic/?pid=fluidigm',
            },
            {
              title: 'Covaris',
              href: '/Genomic/?pid=covaris',
            },
            {
              title: 'CareDx',
              href: '/Genomic/?pid=caredx',
            },
            {
              title: 'Genolution',
              href: '/Genomic/?pid=genolution',
            },
            // {
            //   title: 'Isohelix',
            //   href: '/Genomic/?pid=isohelix',
            // },
            {
              title: 'Jnmedsys',
              href: '/Genomic/?pid=jnmedsys',
            },
            {
              title: 'Twist Bioscience',
              href: '/Genomic/?pid=twist',
            },
            {
              title: 'BMS',
              href: '/Genomic/?pid=bms',
            },
            {
              title: 'ChapterDx',
              href: '/Genomic/?pid=chapter',
            },
          
         

          ],
        },
        contact: {
          groupTitle: 'Contact',
          pages: [
            // {
            //   title: 'Reach View',
            //   href: '/contact-page',
            // },
          
            {
              title: 'Connect',
              href: '/contact-page-cover',
            },
          ],
        },
        blog: {
          groupTitle: 'Cell Biology',
          pages: [
            // {
            //   title: 'Unchained Labs',
            //   href: '/Cellbiology/?pid=unchained',
            // },
            {
              title: 'Fluidigm',
              href: '/Cellbiology/?pid =fluidigm',
            },
            {
              title: 'Protein Simple',
              href: '/Cellbiology/?pid=ps',
            },
            {
              title: 'Codex Dna',
              href: '/Cellbiology/?pid=codex',
            },
            {
              title: 'Askion',
              href: '/Cellbiology/?pid=askion',
            },
            {
              title: 'Agilent',
              href: '/Cellbiology/?pid=acea',
            },
            {
              title: 'Genscript',
              href: '/Cellbiology/?pid=genscript',
            },
            {
              title: 'IndevR',
              href: '/Cellbiology/?pid=indev',
            },
          ],
        },
        portfolio: {
          // groupTitle: 'Quickbook',
          pages: [
            // {
            //   title: 'All',
            //   href: '/',
            // },
            // {
            //   title: 'Premium',
            //   href: '/',
            // },
            // {
            //   title: '10x Genomics',
            //   href: '/',
            // },
            // {
            //   title: 'illumina',
            //   href: '/',
            // },
          ],
        },
      },
    },
    // account: {
    //   title: 'Knowledge Hub',
    //   id: 'account',
    //   children: {
    //     settings: {
    //       groupTitle: 'Blog',
    //       pages: [
    //         {
    //           title: 'All post',
    //           href: '/',
    //         },
    //         {
    //           title: 'Recent',
    //           href: '/',
    //         },
    //         {
    //           title: 'Contributors',
    //           href: '/',
    //         },
          
    //       ],
    //     },
        Genomic: {
          title: 'Genomic',
          id: 'genomic',


          groupTitle: 'Set',
          pages: [
            {
              title: 'illumina',
              href: '/Genomic/?pid=illumina',
            },
            {
              title: 'Security',
              href: '/Genomic/?pid=genomicrange',
            },
            {
              title: 'Notifications',
              href: '/Genomic/?pid=fluidigm',
            },
            {
              title: 'Billing',
              href: '/Genomic/?pid=covaris',
            },
          ],
        },

        Cellbiology: {
          title: 'Cellbiology',
          id: 'cellbiology',


          groupTitle: 'Set',
          pages: [
            {
              title: 'unchained',
              href: '/cellbiology/?pid=unchained',
            },
         
          ],
        },



        signup: {
          groupTitle: 'Sign up',
          pages: [
            {
              title: 'Simple',
              href: '/signup-simple',
            },
            {
              title: 'Cover',
              href: '/signup-cover',
            },
          ],
        },
        signin: {
          groupTitle: 'Sign in',
          pages: [
            {
              title: 'Simple',
              href: '/signin-simple',
            },
            {
              title: 'Cover',
              href: '/signin-cover',
            },
          ],
        },
        password: {
          groupTitle: 'Password reset',
          pages: [
            {
              title: 'Simple',
              href: '/password-reset-simple',
            },
            {
              title: 'Cover',
              href: '/password-reset-cover',
            },
          ],
        },
        error: {
          groupTitle: 'Error',
          pages: [
            {
              title: 'Simple',
              href: '/not-found',
            },
            {
              title: 'Cover',
              href: '/not-found-cover',
            },
          ],
        },
      };
    
  

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={
        clsx({
          [classes.root]: true,
        })
      }
    >
      <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} themeMode={themeMode} themeToggler={themeToggler} />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>
        <Divider />
        {children}
      </main>
      <Footer pages={pages} />
    </div >
  );
};

Main.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Main;
