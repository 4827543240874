import React from 'react';
import {Link} from 'gatsby';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer,  IconButton, } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedinIcon from '@material-ui/icons/LinkedIn'
import { YouTube } from '@material-ui/icons';



const useStyles = makeStyles(theme => ({
  drawer: {
    width: '100%',
    maxWidth: 325,
  },
  root: {
    height: '100%',
    padding: theme.spacing(1),
  },
  fontWeight900: {
    fontWeight: 900,
  },
  nav: {
    marginBottom: theme.spacing(1),
  },
}));

const Sidebar = props => {
  const { pages, open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={() => onClose()}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
      <br/>
        <br/>
        <br/>
      <Link to = "/">  <Typography align = 'center'
                  variant="h4"
                  color="textPrimary"
                  className={clsx(classes.listItemText, 'menu-item',classes.fontWeight900)}
                >
            About
       
                </Typography></Link>


                <br/>
        <br/>
        <br/>
      <Link to = "/Ourstory">  <Typography align = 'center'
                  variant="h4"
                  color="textPrimary"
                  className={clsx(classes.listItemText, 'menu-item',classes.fontWeight900)}
                >
          Our Story
       
                </Typography></Link>
        <br/>
        <br/>
        <br/>
        <Divider/>
        <br/>
      <Typography align = 'center'
                  variant="h4"
                  color="textPrimary"
                  className={clsx(classes.listItemText, 'menu-item',classes.fontWeight900)}
                >

     Products
     <br/>
     <br/>
     <Link to = "/Genomic"> 
     <Typography color ="secondary">
     Genomics &rarr;</Typography>
     </Link>
<br/>
<Link to = "/Cellbiology"> 
<Typography color ="secondary">
     Cell Biology &rarr;</Typography>
     <br/>
     </Link>
  
     
       
                </Typography>

                <Divider/>
                <br/>


                <Link to = "/blog-reach-view/">  <Typography
                align = 'center'
                  variant="h4"
                  color="textPrimary"
                  className={clsx(classes.listItemText, 'menu-item' ,classes.fontWeight900)}
                >
     Knowledge Hub
       
                </Typography></Link>

                <br/>
                <br/>

                <Link to = "/career-listing-minimal/">  <Typography
                align = 'center'
                  variant="h4"
                  color="textPrimary"
                  className={clsx(classes.listItemText, 'menu-item' ,classes.fontWeight900)}
                >
         Career
       
                </Typography></Link>

<br/>
<br/>
                <Link to = "/contact-page-cover">  <Typography
                align = 'center'
                  variant="h4"
                  color="textPrimary"
                  className={clsx(classes.listItemText, 'menu-item' ,classes.fontWeight900)}
                >
         Connect
       
                </Typography></Link>
             <br/>
<div align = 'center'>
                <a href = "https://www.linkedin.com/company/premas-lifesciences/"  target="_blank"  rel="noreferrer" >
              <IconButton  className={classes.socialIcon}>
                <LinkedinIcon 
                className={classes.icon}/>
                
              </IconButton>

              </a>

              <a target="_blank"  rel="noreferrer" href = "https://www.facebook.com/connectpls/" >
              <IconButton className={classes.socialIcon}>
                <FacebookIcon  className={classes.icon} />
              </IconButton>
              </a>
  
              <a href = "https://twitter.com/PremasLifeSc" target="_blank"  rel="noreferrer" >
              <IconButton target="_blank" className={classes.socialIcon}>
                <TwitterIcon className={classes.icon} />
              </IconButton>
              </a>

              < a href = "https://www.youtube.com/channel/UCBI_XOgvOOUIlFW9pZzYWYg">
              <IconButton target="_blank"className={classes.socialIcon} >
                <YouTube className={classes.icon} />
              </IconButton>
              </a>
              </div>
        {/* <SidebarNav className={classes.nav} pages={pages} onClose={onClose} /> */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
